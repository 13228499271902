<template>
  <!-- ### 卡片(海报/宣传图)-插入各个详情页内的入口组件-所有用户可用(当前不区分是否登录) ### -->
  <div class="cards-container" :class="!isSpread?'isclose':'spread'" :style="cardsStyle">
    <!-- 开启弹窗需要的按钮 -->
    <div>
      <slot v-if="_kind.includes('schedule')">
        <slot v-if="baseData && baseData.base && (Array.isArray(baseData.base) || global.isObjectKeys(baseData.base))">
          <div v-for="(item,key,index) in baseData.base" class="thumbnail" :class="isSpread?'isclose':'spread'" @click="setView(true, index)" :key="'menu_'+key">
            <Icon :_type="'image'" :_size="buttonSize" :_color="iconColor"></Icon>
            查看Day{{ index+1 }}{{ kindName && kindTitle[kindName] || '' }}海报
          </div>
        </slot>
      </slot>
      <div v-else class="thumbnail" :class="isSpread?'isclose':'spread'" @click="setView(true)">
        <Icon :_type="'image'" :_size="buttonSize" :_color="iconColor"></Icon>
        查看{{ kindName && kindTitle[kindName] || '' }}海报
      </div>
    </div>
    <!-- 弹窗 -->
    <div class="full" :class="setFullClass(isSpread)" @click="toCloseImg()">
      <div class="toclose" @click="toCloseImg()">
        <Icon :_type="'close'" :_size="20" :_color="buttonColor"></Icon>
      </div>
      <div class="cards-body" :class="isCut?'':'img-blank'">
        <div class="card-detail" @click="toStop">
          <img class="card-preview" :src="imgUrl_base64" alt="" v-if="isCut">
          <!-- <Model class="card-hide" @isready="setCardInfo" :_kind="_kind.replace('model_','')" :_renderData="_info"></Model> -->
          <Renders 
            v-if="isSpread"
            :_selectparams="JSON.stringify(selectedParams)"
            :_activedata="JSON.stringify(activeData)"
            :_origindata="global.deepClone(originData)"
            :key="_kind+_cardid+_id"
            :class="'card-hide '+(_kind+_cardid+_id)"
            @isready="setCardInfo"
          ></Renders>
        </div>
      </div>
      <div class="menus" v-if="isCut">
        <div class="menu" @click="shareImg">
          <Icon :_type="'share'" :_size="buttonSize" :_color="iconColor"></Icon>
          {{ shareLimit ? '分享海报' : '复制链接'}}
        </div>
        <div class="menu" @click="downloadImg">
          <Icon :_type="'down'" :_size="buttonSize" :_color="iconColor"></Icon>
          保存海报
        </div>
        <!-- <div class="menu" @click="refreshImg()">
          <Icon :_type="'img'" :_size="buttonSize" :_color="iconColor"></Icon>
          重新生成
        </div> -->
      </div>
    </div>
    <!-- 开启下载 -->
    <a class="card-download-poster" :href="imgUrl" :download="(imgName||_kind)+'.png'"></a>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';
import mixinUpload from "../../utils/mixinUpload.js"
import { id_arr, cardKeyword, sid, getSue, getSueArr, getSueType, getSueLecturer, cardSueId, cardSueType, getCardIdInfo, getCardInfo, scheduleDifferenceId, scheduledifference } from '../../utils/api.js'

export default {
  name: 'CardIndex',
  components: {
    Icon: ()=>import('../Icon.vue'),
    Renders: () => import('./Renders.vue'),
    // Model: () => import('./Render.vue'),
    // Model: () => import('./Render-copy-3.vue'),
  },
  props: {
    _kind: {
      type: String,
      default: ''
    },
    _info: [Object, Array, String],
  },
  mixins: [ mixinUpload ],
  data() {
    let _this = this;
    return {
      year: _this.global.year,
      sid: sid(_this.global.year),
      scheduleId: false,
      scheduleDifference: false, // 特殊排课
      originData: {}, // 基础数据(专题/课程/日程等) this._info + this.scheduleDifference subject类型需要将特殊排课插入列表内
      activeData: {}, // mrm数据(模板/图片等)
      kindName: '',
      kindTitle: { Subject:'专题', Course:'课程', Schedule:'日程', Case:'案例', Model:'', },
      cardIds: { sid:sid(_this.global.year), year:_this.global.year, subject:false, course:false, schedule:false, case:false, casedetailid:false },
      view: false,
      isSpread: undefined,
      isCut: false,
      imgUrl_base64: '', // url-base64
      imgUrl: '',  // url
      imgFile: '', // file
      imgBlob: '', // blob
      imgName: '', // name
      animationTimeSmall: 0.2, // 2s
      animationTimeLarge: 0.3, // 3s
      buttonSize: 18,
      buttonColor: '#ffffff', // '#003177',
      buttonBgColor: '#003177', // rgba(255,255,255,1),
      iconColor: '#eee',
    }
  },
  computed: {
    cardsStyle() {
      let style = '';
      style += `--animation-time-tiny: ${this.animationTimeSmall*0.6}s};`;
      style += `--animation-time-small: ${this.animationTimeSmall}s;`;
      style += `--animation-time-large: ${this.animationTimeLarge}s;`;
      style += `--color-main: ${this.buttonBgColor};`;
      style += `--color-button: ${this.buttonColor};`;
      return style;
    },
    shareLimit() {
      return 'share' in navigator;
    },
    // 
    // cardIds() {
    //   let idList = {};
    //   if(id_arr[this.year] && id_arr[this.year].cardid) {
    //     idList = { sid: this.sid, year: this.year, ...id_arr[this.year].cardid };
    //   }
    //   return idList;
    // },
    baseData() {
      let data = {base: this._info, diff:this.scheduleDifference};
      // console.log('🩸', this._info, this.scheduleDifference);
      /*  */
      // if(this._kind && this._kind.toLowerCase()) {
      //   let type = this._kind.toLowerCase();
      //   if(type.includes('subject')) { }
      //   if(type.includes('course')) { }
      //   if(type.includes('schedule')) { }
      // }
      return data;
    },
    selectedParams() {
      let type = this._kind && this._kind.toLowerCase() && this._kind.toLowerCase().replace('model_', '');
      let params = {
        year: this.year || false,
        sid: this.sid || false,
        type: type || false,
        cardId: this.cardIds[type] || false,
        venueId: type=='subject'&&this.originData&&this.originData.id || false,
        courseId: type=='course'&&this.originData&&(this.originData.courseId||this.originData.courseid||this.originData.id) || false,
        scheduleId: (type=='schedule')&&(this.scheduleId!==false) ? this.scheduleId : false,
        isView: this.view,
      };
      return params;
    },
    _cardid() {
      return this.selectedParams.cardId;
    },
    _id() {
      let id = this.selectedParams.cardDetailId || (
        this.selectedParams.type=='subject'&&this.selectedParams.venueId
      ) || (
        this.selectedParams.type=='course'&&this.selectedParams.courseId
      ) || (
        (this.selectedParams.type=='schedule'&&this.selectedParams.scheduleId!==false) ? this.selectedParams.scheduleId : false
      );
      if(!(id || id===0 || id==='0')) {
        id = false;
      }
      return id;
    },
  },
  watch: {
    // baseData: {
    //   handler(_new, _old) {
    //     // console.log('Index·watch·base_data ', _new, _old);
    //     if(_new && _new.base && _new.diff) {
    //       // this.getActiveData();
    //     }
    //   },
    //   // immediate: true,
    //   deep: true,
    // },
    selectedParams: {
      handler(_new, _old) {
        // console.log('Index·watch·selected_params ', _new, _old);
        if(_new && this.global.isObjectKeys(_new)) {
          if(_new.isView && (!_old || (typeof _new==='object' && typeof _old==='object' && !this.global.toCompareObject(_new,_old)))) {
            // console.log('Index·watch·selected_params--1 ');
            this.getActiveData();
          }
          if(_old && _new.type=='schedule' && _old.type=='schedule' && (_new.scheduleId !== _old.scheduleId)) {
            // console.log('Index·watch·selected_params--2 ');
            this.setBlank();
          }
        }
      },
      immediate: true,
      deep: true,
    },
    imgUrl_base64: {
      handler(_new, _old) {
        if(_new && _new.length>0) {
          this.isCut = true;
        } else {
          this.isCut = false;
        }
      },
      immediate: true,
      deep: true,
    },
    _kind: {
      handler(_new, _old) {
        // console.log('Index·watch·_kind ', _new, _old);
        if(_new && _new.length>0) {
          let name = _new.toLowerCase();
          this.kindName = name[0].toUpperCase() + name.slice(1);
          this.setCardIdInfo(name);
          if(this.kindName == 'subject') {
            this.getDifference();
          } else {
            this.scheduleDifference = true;
          }
        }
      },
      immediate: true,
      deep: true,
    },
    view: {
      handler(_new, _old) {
        // console.log('Index·watch·view ', _new, _old)
        if(_new) {
          // 展开 spreadFull
          // this.setCard();
          this.isSpread = true;
        } else if(_old===true) {
          // 折叠 closeFull
          setTimeout(() => {
            this.isSpread = undefined;
          }, this.animationTimeLarge*1000/2)
          this.isSpread = false;
        }
        // console.log(this.isSpread);
      },
      immediate: true
    },
    isSpread: {
      handler(_new, _old) {
        // console.log('Index·watch·is_spread ', _new, _old)
        if(_new) {
          this.$nextTick(() => {
            // console.log( $('.cards-container .full.spread .cards-body').scrollTop() );
            let position = $('.cards-container .full.spread .cards-body').scrollTop();
            if(position && position > 0) {
              $('.cards-container .full.spread .cards-body').animate({
                scrollTop: 0
              }, 'fast');
            }
          })
        }
      },
      immediate: false,
    },
  },
  methods: {
    // 
    setFullClass(isSpread) {
      let name = ''
      if(!isSpread && isSpread===false) {
        name = 'isclose';
      } else if(isSpread) {
        name = 'spread';
      }
      return name;
    },

    toStop(e) {
      e.stopPropagation();
    },

    // 关闭弹窗
    toCloseImg() {
      this.view = false;
      this.isSpread = undefined;
      // this.setBlank();
    },
    setBlank() {
      this.imgUrl = '';  // url
      this.imgFile = ''; // file
      this.imgName = ''; // name
      this.imgUrl_base64 = ''; // url_base64
      window.URL.revokeObjectURL(this.imgBlob);
      this.imgBlob = ''; // blob
    },
    
    // 分享
    shareImg(e) {
      if(e) {
        this.toStop(e);
      }
      // this.global.IsPC()
      // pc - 上传图片至七牛云--复制七牛云链接
      // mob - 唤起分享功能 or 长按链接复制分享
        this.qiniuUpload(this.imgFile).then(res => {
          console.log('Index·method·share_img', res);
          if(res && res.qiniuDomain && res.result && res.result.key) {
            let linkUrl = res.qiniuDomain + res.result.key;
            if (this.shareLimit) {
              navigator.share({
                  title: this.imgName,
                  text: '',
                  url: linkUrl
              })
              .then(() => console.log('分享成功'))
              .catch(error => console.log('分享失败', error));
            } else {
              this.copyUrl(linkUrl);
            }
          } else {
            this.$toast('海报链接获取失败，请稍后重试');
          }
        })
      // this.setCard('share')
    },
    // 下载
    downloadImg(e) {
      if(e) {
        this.toStop(e);
      }
      // alert(this.global.IsPC() + ' ' + this.imgUrl);
      if(this.imgUrl && this.imgUrl.length>0) {
        if(this.global.IsPC()) {
          let path = '/download?url=' + this.imgUrl + '&name=' + (this.imgName||this._kind) + '.png';
          // let path = '/download'
          window.open(path, '_blank');
        } else {
          if(this.global.isWeChatBrowser()) {
            alert('请点击右上角，进入浏览器进行下载');
          } else {
            // console.log( $('.card-download-poster') );
            document.getElementsByClassName('card-download-poster')[0].click();
          }
          // var xhr = new XMLHttpRequest();
          // xhr.open('GET', this.imgUrl, true);
          // xhr.responseType = 'blob';
          // xhr.onprogress = (event) => {
          //   if (event.lengthComputable) {
          //     var percentComplete = event.loaded / event.total;
          //     console.log(percentComplete * 100 + '% downloaded');
          //     if(percentComplete == 1 && this.global.IsPC()) {
          //       this.$toast('下载成功');
          //     }
          //   }
          // };
          // xhr.onload = () => {
          //   var blob = xhr.response;
          //   var url = window.URL.createObjectURL(blob);
          //   var a = document.createElement('a');
          //   a.href = url;
          //   a.download = (this.imgName||this._kind) + '.png';
          //   a.style.display = 'none';
          //   a.click();
          //   window.URL.revokeObjectURL(url);
          // };
          // xhr.send();
        }
      }
    },
    // 刷新
    // refreshImg() {
    //   this.isSpread = true;
    //   setTimeout(() => {
    //     // this.setCard();
    //   }, this.animationTimeSmall/2*1000);
    // },

    setView(v, id) {
      // console.log('Index·method·set——view', v, id, this.baseData);
      if(this._kind.includes('schedule') && (id || id===0 || id==='0')) {
        this.scheduleId = id;
        let allSchedule = this.baseData.base;
        if(allSchedule && allSchedule[id]) {
          this.originData = allSchedule[id];
        }
        // console.log('⭐️⭐️', this.originData);
        // this.originData = dayList[id] && this.baseData.base && this.baseData.base[dayList[id]] || this.baseData.base;
      } else {
        this.scheduleId = false;
        this.originData = this.baseData.base || {}; // 》》》》》》
      }
      this.view = (!v || v==='false' || v==='0') ? false : Boolean(v);
    },
    getPeriod(times) {
      let period = 'am';
      if(times && times.length > 0) {
        let hours = times.slice(0,2);
        if(!hours.includes(':') && !hours.includes('：')) {
          // let minutes = times.slice(3,5);
          if(hours > '12') {
            period = 'pm';
          }
        }
      }
      return period;
    },

    // 接口请求成功
    setCardInfo(res) {
      console.log('👌🏻act-index ', res);
      if(res && res.status && res.status == '200') {
        setTimeout(() => {
          this.setCard(); // 》》》》》》
        }, 20)
      }
    },
    // 截屏
    setCard(shareType) {
      // return new Promise((resolve, reject) => {
        // 给图片添加属性 crossOrigin="Anonymous" 应对图片跨域问题
        // id=app 的Dom 截图  document.querySelector("#app")
        // 改为标签body的截图  document.body
        // let modelId = this._info.id ? `#sharecard_${this._kind.toLocaleLowerCase()}_${this._info.id}` : '#sharecard_model';
        // let modelId = `#sharecard_${this._kind.toLocaleLowerCase()}`;
        let modelId = `#sharecard_model_${this._kind.toLocaleLowerCase()}_${this._cardid}_${this._id}`; // Renders.vue
        // let modelId = '.sharecard_model';
        let canvasID = document.querySelector(modelId);
        console.log(modelId, shareType||'');
        html2canvas(canvasID, {
          useCORS: true, // 开启跨域配置
          // scale: window.devicePixelRatio < 3 ? window.devicePixelRatio : 2,
          scale: 2, // 将输出图像的分辨率设置为屏幕分辨率的两倍
          allowTaint: true, // 允许跨域图片
          dpi: 300, // 处理模糊问题
        }).then((canvas) => {
          //获取年月日作为文件名-年月日加随机数
          var timers = new Date();
          var fullYear = timers.getFullYear();
          var month = timers.getMonth() + 1;
          var date = timers.getDate();
          var randoms = Math.random() + '';
          var theTime = fullYear + '' + ( month < 10 ? '0' : '' ) + month + ( date < 10 ? '0' : '' ) + date;
          this.imgName = `share${theTime}${randoms.slice(3, 10)}`;
          // dom
          let dom = document.body.appendChild(canvas);
          dom.style.display = 'none';
          document.body.removeChild(dom);
          // console.log('📢 set_card', dom, dom.toDataURL('image/png'));
          this.imgBlob = this.global.dataURLToBlob(dom.toDataURL('image/png'));
          // url file
          this.imgUrl = window.URL.createObjectURL(this.imgBlob);
          this.imgFile = new File([this.imgBlob], this.imgName, {
            type: 'image/png', // 'application/json',
            lastModified: Date.now()
          });
          this.global.tirggerFile(this.imgFile).then(url => {
            if(url) {
              this.imgUrl_base64 = url;
            }
          })
          // if(shareType && shareType.length>0) {
          //   if(shareType === 'save') {
          //     resolve({ url: this.imgUrl, name: this.imgName });
          //   }
          // }
        })
      // })
    },

    // 
    setCardIdInfo(type) {
      this.$http.jsonp(getCardIdInfo + `?sid=${this.sid}`).then((res) => {
        return res.json();
      }).then((res) => {
        if(res.status = '200' && this.global.isObjectKeys(res.data)) {
          var dataArr = Object.values(res.data).sort(this.global.toSortKey('id',0,'number'));
          for(let item of dataArr) {
            let key = '';
            for(let k in cardKeyword) {
              if(item.typeName && item.typeName.includes(cardKeyword[k])) {
                key = k;
                break;
              }
            }
            if(item.id && type == key && !this.cardIds[type]) {
              this.cardIds[type] = item.id;
            }
          }
        }
      })
    },
    getActiveData() {
      let _params = this.selectedParams;
      if(_params.sid && _params.cardId) {
        let params = `?sid=${_params.sid}&cardId=${_params.cardId}&cardDetailId=${_params.cardDetailId||''}&courseId=${_params.courseId||''}&venueId=${_params.venueId||''}`;
        this.$http.jsonp(getCardInfo+params)
        .then(res => {
          return res.json();
        })
        .then(res => {
          // console.log('Index·method·get_active_data ', res);
          if(res && res.status=='200' && res.data) {
            this.activeData = res.data;
            this.$forceUpdate();
          }
        })
      }
    },
    getDifference() {
      let _year = this.year;
      if(_year && scheduledifference(_year) && typeof scheduledifference(_year)==='object') {
        let url = getSueType + scheduleDifferenceId;
        this.$http
          .jsonp(url)
          .then((data) => {
            return data.json();
          })
          .then((res) => {
            if (res.errno == '0' && res.data) {
              let type = '';
              let typeList = [];
              for(let item of res.data) {
                type = item.typeName && typeof item.typeName==='string' && item.typeName.toLowerCase();
                if(type && type.includes('giac')) {
                  typeList.push({id:item.id, name:type.split('#') && type.split('#')[1]});
                }
              }
              this.getScheduleDifference(_year, typeList);
            } else {
              this.scheduleDifference = true;
            }
          })
      } else {
        this.scheduleDifference = true;
      }
    },
    getScheduleDifference(_year, typeList) {
      if(typeList && typeList.length>0) {
        let diff = scheduledifference(_year);
        for(let index in Object.keys(diff)) {
          diff[Object.keys(diff)[index]].courses = [];
        }
        let url = getSueArr + scheduleDifferenceId;
        this.$http
          .jsonp(url)
          .then((data) => {
            return data.json();
          })
          .then((res) => {
            // console.log("difference-data: ", res, typeList, diff);
            if (res.errno == '0' && res.data) {
              for(let typeItem of typeList) {
                if(diff[typeItem.name]) {
                  diff[typeItem.name].courses = [];
                  diff[typeItem.name].timeStamp = new Date().getTime();
                  let course = {}, lecturers=[], lecturer='', times=[], titles=[], title='';
                  for(let index in res.data[0]) {
                    let course = res.data[0][index];
                    lecturers = course.content && course.content.length>0 && course.content.split(' /// ');
                    if(course.content_note && course.content_note.length>0) {
                      times = (course.content_note.split('scheduleTitle--')[0] || '').replace('time--','').replace(/\r\n/g,'').split('-');
                      // titles = (course.content_note.split('scheduleTitle--')[1] || '').replace(/^\r\n/,'').split('\r\n');
                    }
                    diff[typeItem.name].courses.push({
                      courseTitle: course.title,
                      lecturers: [],
                      times: times,
                    });
                    for(let lindex in lecturers) {
                      lecturer = lecturers[lindex] || '';
                      // title = titles[lindex] || '';
                      if(lecturer.split(' || ')[0] && lecturer.split(' || ')[0].trim() !== '客户技术高层') {
                        diff[typeItem.name].courses[index].lecturers.push({
                          name: lecturer.split(' || ')[0] || '',
                          company: lecturer.split(' || ')[1] || '',
                          position: lecturer.split(' || ')[2] || '',
                          // courseTitle: title, // 不需要显示讲师议题
                        });
                      }
                    }
                  }
                }
              }
            }
            this.scheduleDifference = JSON.parse(JSON.stringify(diff));
          })
      } else {
        this.scheduleDifference = true;
      }
    }

  },
}
</script>

<style lang="scss">
.cards-container {
  width: 100vw;
  // height: 100vh;
  height: 200vh;
  position: fixed;
  top: 0;
  // bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  // display: flex;
  // justify-content: center;
  // align-items: flex-start;
  // padding: 30px;
  // z-index: 500;
  z-index: 450;
  // transition: all var(--animation-time-tiny) linear;
  // background: linear-gradient(180deg, rgba(0,0,0,0.06) 0%, rgba(0,0,0,0.06) 50%, transparent 100%);
  background: linear-gradient(180deg, rgba(0,0,254,0.06) 0%, rgba(0,0,254,0.04) 50%, transparent 100%);
  --background-color-button: linear-gradient(90deg, #7987E8 0%, #00C3FE 100%);
  --opacity-spread: 0.6;
  --opacity-close: 0.6;
  &.isclose {
    height: 0;
  }
  .thumbnail {
    width: fit-content;
    height: fit-content;
    margin: 0;
    padding: 8px 13px;
    font-size: 16px;
    font-weight: bold;
    color: var(--color-button);
    // text-shadow: 0 1px 2px #fff;
    text-shadow: 0 1px 2px var(--color-main);
    // letter-spacing: 1px;
    user-select: none;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    // bottom: 80px;
    // right: 0px;
    right: -150px;
    z-index: 501;
    // border-radius: 6px;
    border-radius: 8px;
    // border: 1px solid var(--color-button);
    // border: 1px solid var(--color-main);
    border: 2px solid #00317766;
    background: var(--background-color-button);
    background-origin: border-box;
    opacity: 0;
    cursor: pointer;
    .icon-defined {
      margin-right: 8px;
    }
    &:nth-last-of-type(1) {
      bottom: 80px;
    }
    &:nth-last-of-type(2) {
      bottom: 132px;
    }
    &:nth-last-of-type(3) {
      bottom: 184px;
    }
    &.spread {
      right: 0;
      opacity: var(--opacity-spread);
      animation: spreadThumbnail var(--animation-time-large) linear;
      &:hover {
        // background-color: rgba(0, 110, 220, 0.2);
        // background-color: rgba(255,255,255,0.5);
        opacity: 1;
      }
    }
    &.isclose {
      animation: closeThumbnail var(--animation-time-small) linear;
    }
    @keyframes spreadThumbnail {
      0% { right: -150px; opacity: 0; }
      10% { right: 0; opacity: var(--opacity-spread); }
    }
    @keyframes closeThumbnail {
      0% { right: 0; opacity: var(--opacity-spread); }
      10% { right: -150px; opacity: 0; }
    }
  }
  .full {
    width: 100vw;
    height: 100vh;
    // height: auto;
    display: flex;
    justify-content: center;
    // align-items: center;
    align-items: flex-start;
    // padding: 30px;
    position: fixed;
    // position: relative;
    // top: 0;
    top: -100vh;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 500;
    border: none;
    overflow: auto;
    &::before {
      content: '';
      width: 100vw;
      height: 200vh;
      display: block;
      position: fixed;
      // top: 0;
      top: -200vh;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 490;
      // background-color: #000000;
      // background: linear-gradient(180deg, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0.35) 50%, transparent 100%);
      background: linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.3) 50%, transparent 100%);
      // background: linear-gradient(180deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.5) 50%, transparent 100%);
    }
    &.spread {
      top: 0;
      animation: spreadFull var(--animation-time-large) linear;
      &::before {
        top: 0;
        animation: spreadFullBefore var(--animation-time-large) linear;
      }
      .cards-body .card-detail .card-preview {
        opacity: 1;
        transition: opacity var(--animation-time-large) linear;
      }
      .toclose {
        top: 10px;
      }
      .menus {
        bottom: 20px;
      }
      .cards-body {
        top: 0;
        bottom: 0;
        // display: block;
        // opacity: 1;
        // transition: display var(--animation-time-large) linear;
        &.img-blank::after {
          content: '加载中...';
          font-size: 18px;
          font-weight: bold;
          color: #fff;
          position: absolute;
          top: calc(50% - 30px);
          left: 50%;
          z-index: 1;
          transform: translateX(-50%);
        }
      }
    }
    &.isclose {
      animation: closeFull var(--animation-time-large) linear;
      &::before {
        animation: closeFullBefore var(--animation-time-large) linear;
      }
      .cards-body .card-detail .card-preview {
        opacity: 0;
        transition: opacity var(--animation-time-large) linear;
      }
      .cards-body {
        top: -100vh;
        bottom: 100vh;
        // display: block;
        // display: none;
        // opacity: 0;
        // transition: display var(--animation-time-large) linear;
      }
    }
    @keyframes spreadFull {
      0% { top: -100vh; opacity: 0; }
      100% { top: 0; opacity: 1; }
    }
    @keyframes closeFull {
      0% { top: 0; opacity: 1; }
      100% { top: -100vh; opacity: 0; }
    }
    @keyframes spreadFullBefore {
      0% { top: -200vh; }
      100% { top: 0; }
    }
    @keyframes closeFullBefore {
      0% { top: 0; }
      100% { top: -200vh; }
    }
    .toclose {
      position: fixed;
      // top: 10px;
      top: -100vh;
      z-index: 601;
      right: 10px;
      padding: 9px;
      border-radius: 50%;
      border: 1px solid var(--color-button);
      // border: 1px solid var(--color-main);
      background: var(--background-color-button);
      // background-color: var(--color-main);
      opacity: var(--opacity-close);
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
    .menus {
      position: fixed;
      // bottom: 20px;
      bottom: -100px;
      right: 10px;
      z-index: 601;
      .menu {
        margin-top: 10px;
        width: fit-content;
        height: fit-content;
        padding: 7px 12px;
        font-size: 16px;
        font-weight: bold;
        color: var(--color-button);
        // text-shadow: 0 1px 2px #fff;
        text-shadow: 0 1px 2px var(--color-main);
        user-select: none;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 1px solid var(--color-button);
        // border: 1px solid var(--color-main);
        background: var(--background-color-button);
        opacity: var(--opacity-close);
        cursor: pointer;
        .icon-defined {
          margin-right: 8px;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
    .cards-body {
      // width: auto;
      // height: auto;
      width: 100%;
      height: 100%;
      padding: 30px 0;
      margin: 0 auto;
      // position: relative;
      position: fixed;
      top: -100vh;
      left: 0;
      right: 0;
      bottom: 100vh;
      z-index: 550;
      -webkit-scrollbar: none; /* 隐藏滚动条对于Webkit内核的浏览器（如Chrome和Safari） */
      -ms-overflow-style: none; /* 隐藏滚动条对于IE 10+ */
      scrollbar-width: none; /* 隐藏滚动条对于Firefox */
      overflow-x: hidden;
      overflow-y: auto;
      // opacity: 0;
      // display: none;
      // &::before, &::after {
      //   content: '';
      //   display: block;
      //   width: 100%;
      //   height: 30px;
      // }
      .card-detail {
        width: fit-content;
        height: 100%;
        // margin: 0 auto;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        .card-preview {
          max-width: 600px;
          height: auto;
          margin-bottom: 30px;
          opacity: 0;
        }
        .card-hide {
          position: fixed!important;
          bottom: 100vh!important;
        }
        .cards-background {
          height: auto;
        }
        .cards-content {
          // width: 100%;
          // height: 100%;
          // position: absolute;
          // top: 0;
          // bottom: 0;
          // left: 0;
          // right: 0;
          background: transparent;
          // px = pt * DPI / 72
          * {
            line-height: 1;
            margin-top: 0;
            margin-bottom: 0;
            color: #000000;
          }
        }
      }
    }
  }
  @media (max-width:767px) {
    .thumbnail {
      // bottom: 60px;
      // top: 80px;
      // bottom: auto;
      bottom: 130px;
      &.spread {
        opacity: 1;
      }
    }
    .full {
      &.spread .cards-body {
        padding: 60px 0;
        .card-detail .card-preview {
          max-width: 90vw;
          margin-bottom: 120px;
        }
      }
      .toclose, .menus .menu {
        opacity: 1;
      }
    }
  }
}

@font-face {
  font-family: "Alibaba PuHuiTi Regular";
  src: url("https://f.msup.com.cn/Alibaba%20PuHuiTi%20Regular%201M.ttf");
}
@font-face {
  font-family: "Alibaba PuHuiTi Medium";
  src: url("https://f.msup.com.cn/Alibaba-PuHuiTi-Medium-spider.ttf");
}
@font-face {
  font-family: "Alibaba PuHuiTi Heavy";
  src: url("https://f.msup.com.cn/Alibaba-PuHuiTi-Heavy-.ttf");
  // src: url("https://f.msup.com.cn/Alibaba-PuHuiTi-Heavy-spider.ttf");
}
@font-face {
  font-family: "Alibaba PuHuiTi Bold";
  src: url("https://f.msup.com.cn/Alibaba-PuHuiTi-Bold.ttf");
  // src: url("https://f.msup.com.cn/AlibabaPuHuiTi-Bold%20small.ttf");
}
@font-face {
  font-family: "Alibaba PuHuiTi Light";
  src: url("https://f.msup.com.cn/Alibaba-PuHuiTi-Light-spider.ttf");
}
</style>